<template>
    <div
        class="fixed w-full bg-white nav:bg-white/[.9] nav:backdrop-blur-sm z-20 flex pb-[25px] pt-4 px-[25px] lg:px-[75px] justify-center items-center"
    >
        <a
            href="//swingu.com"
            class="mr-auto w-[101px] h-[36px] z-10"
        >
            <SwinguLogo />
        </a>

        <div
            class="flex nav:hidden z-10"
        >
            <HamburgerMenuAnimated
                ref="hamburgerMenuIcon"
                :open="mobileNavOpen"
                @click="mobileNavOpen = !mobileNavOpen"
            />
        </div>

        <nav
            data-class=""
            class="nav:flex nav:static absolute bg-white nav:bg-transparent nav:w-auto w-full pt-[85px] nav:pt-0 top-[0px] duration-200 ease-in-out text-lg nav:text-sm text-gray-800 font-semibold pb-xl nav:pb-0"
            :class="[mobileNavOpen ? 'top-0 left-0' : 'left-[-100%]']"
        >
            <ul
                class="nav:flex nav:gap-[20px]"
            >
                <li
                    v-for="(link, index) in links"
                    :key="link.href"
                >
                    <a
                        :href="link.href"
                        class="py-sm nav:py-4 px-[25px] nav:px-0 block nav:inline-block hover:text-[var(--medium-primary)] transition duration-300"
                    >
                        {{ link.title }}
                    </a>
                </li>
                <li v-if="subscriptionTier != 20">
                    <a
                        :href="upgradeLink"
                        class="py-sm nav:py-4 px-[25px] nav:px-0 block nav:inline-block hover:text-[var(--medium-primary)] transition duration-300"
                    >
                        {{ upgradeTitle }}
                    </a>
                </li>
                <li class="group flex nav:justify-end">
                    <a  
                        v-if="playerName === 'Login'"
                        :href="authSubdomainBaseLink"
                        class="py-sm nav:py-4 px-[25px] nav:px-0 block nav:inline-block text-green-600 hover:text-[var(--dark-secondary)] transition duration-300"
                    >
                        {{ playerName }}
                    </a>
                    <div 
                        v-else
                        class="py-sm nav:py-4 px-[25px] nav:px-0 flex nav:justify-end text-green-600 hover:text-[var(--dark-secondary)] transition duration-300"
                    >
                        <div class="flex items-center gap-[5px] group-hover:text-[var(--medium-primary)] transition-colors duration-500 ease-in-out">
                            {{ playerName }}
                        </div>
                    </div>
                </li>
            </ul>
        </nav>
    </div>

</template>
<script setup>
import {ref} from "vue";
import SwinguLogo from "@svg/swingu-logo.vue";
import HamburgerMenuAnimated from "@global/ui/hamburger-menu-animated.vue";
import { usePage } from '@inertiajs/inertia-vue3';
import { computed } from 'vue';

const mobileNavOpen = ref(false);

const links = [
    {
        href: '//clubhouse.swingu.com',
        title: 'Clubhouse',
    },
    {
        href: '//swingu.com/coach',
        title: 'Coaches',
    },
    {
        href: '//help.swingu.com',
        title: 'Support',
    },
];

const upgradeLink = "//swingu.com/upgrade";
const upgradeTitle = "Upgrade";

const playerName = computed(() => {
    let retVal = usePage().props.value.auth.user.username;
    return retVal === "No User" ? 'Login' : retVal;
});

const subscriptionTier = computed(() => {
    return usePage().props.value.auth.user.subscriptionTier;
});

const authSubdomainBaseLink = computed(() => {
    return '//' + usePage().props.value.subdomains.authSubdomain;
});
</script>
